import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import { SRLWrapper } from 'simple-react-lightbox';
import './projectTemplate.css';
import HeaderWrapper from '../components/HeaderWrapper';
import { Link } from 'gatsby';

export default function Template({ data, location }) {
	const { markdownRemark } = data;
	const { frontmatter, html } = markdownRemark;
	const galleryProjects = data.markdownRemark.frontmatter.gallery;
	const featuredImageUrl = getSrc(frontmatter.featuredImage);
	const pageLanguage = frontmatter.language;
	const optionsLightbox = {
		caption: {
			showCaption: false,
		},
		buttons: {
			showDownloadButton: false,
		},
		thumbnails: {
			showThumbnails: false,
		},
	};
	return (
		<Layout location={location} lang={pageLanguage} isDisabled={true}>
			<div className="project-container">
				<div className="project">
					<HeaderWrapper
						featuredImageUrl={featuredImageUrl}
						title={frontmatter.title}
					></HeaderWrapper>

					<div className="text-center pt-10 pb-5 text-xl text-white bg-black">
						{pageLanguage === 'en' ? (
							<div>
								<p className="inline-block">
									<strong>Description:&nbsp;</strong>
								</p>
								<div
									className="inline-block"
									dangerouslySetInnerHTML={{ __html: html }}
								></div>
							</div>
						) : (
							<div>
								<p className="inline-block">
									<strong>Описание:&nbsp;</strong>
								</p>
								<div
									className="inline-block"
									dangerouslySetInnerHTML={{ __html: html }}
								></div>
							</div>
						)}
						{pageLanguage === 'en' ? (
							<p>
								<strong>Location:&nbsp;</strong>
								{frontmatter.location}
							</p>
						) : (
							<p>
								<strong>Локация:&nbsp;</strong>
								{frontmatter.location}
							</p>
						)}
						{pageLanguage === 'en' ? (
							<p>
								<strong>Square meters:&nbsp;</strong>
								{frontmatter.size}m<sup>2</sup>
							</p>
						) : (
							<p>
								<strong>Площ:&nbsp;</strong>
								{frontmatter.size}м<sup>2</sup>
							</p>
						)}
						{pageLanguage === 'en' ? (
							<p>
								<strong>Date:&nbsp;</strong>
								{frontmatter.timeframe}
							</p>
						) : (
							<p>
								<strong>Дата:&nbsp;</strong>
								{frontmatter.timeframe}
							</p>
						)}
					</div>

					<SRLWrapper options={optionsLightbox}>
						<div className="grid grid-cols-1 md:grid-cols-2">
							{galleryProjects.map((p, index) => (
								<div key={index} className="flex-1 bg-black cursor-pointer">
									<GatsbyImage
										className="hover:opacity-40"
										image={p.childImageSharp.gatsbyImageData}
										alt={p.name}
										style={{ height: '100%', width: '100%' }}
										imgStyle={{ objectFit: 'inherit' }}
									/>
								</div>
							))}
						</div>
					</SRLWrapper>
					<div className="text-center py-14 text-xl text-white bg-black">
						<Link
							to={
								pageLanguage === 'en'
									? `/projects`
									: `/${pageLanguage}/projects`
							}
							className="inline-flex items-center font-semibold hover:text-gray-400"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="inline-block h-6 w-6"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fillRule="evenodd"
									d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
									clipRule="evenodd"
								/>
							</svg>
							{pageLanguage === 'en' ? `All Projects` : `Всички Проекти`}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="inline-block h-6 w-6"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fillRule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clipRule="evenodd"
								/>
							</svg>
						</Link>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const projectQuery = graphql`
	query($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			html
			frontmatter {
				gallery {
					publicURL
					absolutePath
					name
					childImageSharp {
						gatsbyImageData(formats: [AUTO, WEBP, AVIF])
					}
				}
				location
				size
				timeframe
				slug
				language
				title
				featuredImage {
					name
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
					}
				}
			}
		}
	}
`;
